@use "../../styles/variables" as *;

.countdown {

    &__clock {
        padding-bottom: 8px;
    }

    &__headline {
        color: yellowgreen;
        font-size: 2rem;
    }
}