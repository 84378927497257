@use "../../styles/variables" as *;

.modal {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: black;
    
    &__content {
        height: 100%;
        margin: $spacing-default;
        margin-top: 0;
        border-top: $border-default;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}