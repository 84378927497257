@mixin mobile-large {
    @media (min-height: 641px) {
        @content;
    };
}

@mixin landscape {
    @media (max-height: 500px) {
        @content;
    }
}