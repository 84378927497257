@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;


.buttons {
    position: relative;
    bottom: 0.4rem;
    width: 100%;
    height: calc(#{$button-height} / 2);
    padding-left: $spacing-default;
    padding-right: $spacing-default;
    background-color: black;
    display: flex;
    justify-content: center;
    gap: $spacing-default;

    @include mobile-large {
      bottom: 0;
    }
  }