@use "../../styles/variables" as *;

.void {
    width: 100%;
    min-height: 400px;
    padding: $spacing-default;
    padding-top: 0;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &__socials {
        width: 100%;
        max-width: 200px;
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__img {
        width: 50px;
        height: 50px;
    }

    &__buttons {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: $spacing-default;
    }

    &__item {
        width: 100%;
        display: grid;
        place-items: center;
    }
}