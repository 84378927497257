@use "./variables" as *;
@use "./mixins" as *;

@font-face {
    font-family: 'Effra';
    src: url('../assets/fonts/effra/EffraHeavy-Regular.woff2') format('woff2'),
        url('../assets/fonts/effra/EffraHeavy-Regular.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'EB Garamond';
    src: url('../assets/fonts/garamond/EBGaramond-Regular.woff2') format('woff2'),
        url('../assets/fonts/garamond/EBGaramond-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}


.heading {
    font-size: 1.125rem;
    padding-bottom: $spacing-default;
  
    @include mobile-large {
      font-size: 1.25rem;
    }

    &--alt {
        color: $clr-highlight;
    }
}

.headline {
    font-size: 1.25rem;
    font-weight: 900;
    padding-bottom: $spacing-default;

    @include mobile-large {
        font-size: 1.75rem;
    }

    &--warning {
        color: $clr-warning;
    }

    &--alt {
        color: $clr-main;
    }

    &--highlight {
        color: $clr-highlight;
    }
}

.headline-clock {
    color: $clr-highlight;
    font-size: 2rem;
}

.text-default {
    color: $clr-alt;
    font-size: .75rem;
    padding-bottom: $spacing-default;
  
    @include mobile-large {
      font-size: .875rem;
    }
}

.text-alt {
    font-family: 'EB Garamond';
    font-size: .625rem;

    @include mobile-large {
        font-size: .75rem;
    }
}

.text-button {
    font-family: 'Effra';
    font-size: 1.5rem;
}