@use "./variables" as *;
@use "./fonts" as *;


.button {
  @extend .text-button;
  width: 100%;
  max-width: 200px;
  height: $button-height;
  border: none;
  background-color: $clr-highlight;
  display: grid;
  place-items: center;
}

.list-default {
  list-style-type: none;
}

.link-default {
  color: $clr-main;
  text-decoration: none;
}

.border-default {
  border-bottom: $border-default;
  margin-bottom: $spacing-default;
}