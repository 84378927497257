@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.card {
    width: 100%;
    min-height: 425px;
    padding-top: calc(#{$spacing-default} / 2);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    text-align: center;
    background-color: $clr-main;
  }