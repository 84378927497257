$spacing-default: 8px;
$border-default : 2px solid white;
$button-height: 50px;

// Responsive Design
// Screen
$min-screen-size: 280px;
$min-screen-height: 568px;

// Colour Defaults
$clr-main: #000000;
$clr-alt: #fff;
$clr-highlight: #aaca34;
$clr-highlight-active: #88a22a;
$clr-warning: #d01726;