@use "../../styles/mixins" as *;

.card-header {
    position: relative;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;

    @include mobile-large {
        height: 45px;
    }

    &__canvas {
        position: absolute;
        top: -40px;
        width: 100%;
        height: 50px;

        @include mobile-large {
            top: -50px;
        }
        
        &--panda {
            max-width: 200px;
        }
    }

    &__img {
        width: 100%;
    }
}