@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.voucher {

    &__canvas {
      width: 100%;
      min-height: 248px;
      padding-bottom: $spacing-default;
    }
  
    &__img {
      width: 100%;
      max-width: 250px;

      @include mobile-large {
        max-width: none;
      }


    }
  
    &__content {
      position: relative;
      padding-left: $spacing-default;
      padding-right: $spacing-default;
    }
  }