@use "./styles/globals" as *; 
@use "./styles/variables" as *; 
@use "./styles/mixins" as *; 
@use "./styles/fonts" as *; 

*, *::before, *::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.App {
  width: 100%;
  min-width: 280px;
  height: 100vh;
  min-height: 500px;
  overflow-x: hidden;
  padding: $spacing-default 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Effra", "Open Sans", 'Arial Black', Verdana;
  color: $clr-alt;
  
  @include landscape {
    height: auto;
  }

  &__content {
    width: inherit;
    max-width: 500px;
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  min-height: $min-screen-height;
  object-fit: cover;
  z-index: -1;
  background-color: $clr-main;
}

// CSS Sandbox

.heading-2 {
  color: white;
  font-size: 1.125rem;
  padding-bottom: $spacing-default;

  @include mobile-large {
    font-size: 1.25rem;
  }
}

.text-default {
  color: white;
  font-size: .75rem;
  padding-bottom: $spacing-default;

  @include mobile-large {
    font-size: .875rem;
  }
}

.border-default {
  border-bottom: $border-default;
  margin-bottom: $spacing-default;
}