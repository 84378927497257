.header {
    width: 100%;
    height: 60px;
    display: grid;
    place-items: center;
    z-index: 1;
    
    &__logo {
        width: inherit;
        max-width: 370px;
    }
}